import * as React from "react"
import { Link, graphql } from "gatsby"

// markup
const WorkoutsPage = (props) => {
  const workouts = props.data.allWorkout.nodes
  
  return (
    <main className="prose">
      <h1>All Workouts</h1>
      {workouts.map(workout => {
        return (
          <li key={workout.id}><Link to={`/workout/${workout.id}`}>{workout.title}</Link></li>
        )
      })}
    </main>
  )
}

export default WorkoutsPage

export const query = graphql`
query {
  allWorkout {
    nodes {
      id
      title
    }
  }
}
`